<template>
  <div v-if="sign_stat<21" class="signPlan">
    <div class="group-button flex">
      <div
        v-if="sign_stat==0 || sign_stat==1"
        style="width:calc(100% / 7);position:absolute;left:0;"
      >
        <el-button @click="gotoSign(item)" size="small" type="primary">去签约</el-button>
      </div>
      <div
        v-if="sign_stat>=2 && sign_stat<4"
        style="min-width:170px;width:calc(100% / 7);position:absolute;left:calc(100% / 7 *1);"
      >
        <el-button @click="lookContract(item)" size="small" type="primary">查看合同</el-button>
        <el-button @click="downloadContract(item)" size="small" type="primary">下载合同</el-button>
      </div>
      <div
        v-if="sign_stat>=4"
        style="min-width:170px;width:calc(100% / 7);position:absolute;left:calc(100% / 7 *3);"
      >
        <el-button @click="lookContract(item)" size="small" type="primary">查看合同</el-button>
        <el-button @click="downloadContract(item)" size="small" type="primary">下载合同</el-button>
      </div>
      <div
        v-if="sign_stat==4"
        style="min-width:170px;width: calc(100% / 7);position:absolute;left:calc(100% / 7 *4);"
      >
        <el-button @click="goPay(item)" size="small" type="primary" plain>去付款</el-button>
        <el-button
          v-if="item.tradeOrder && item.tradeOrder.paidAmount>0"
          @click="view_pay(item)"
          size="small"
          type="primary"
        >付款查询</el-button>
      </div>
      <div
        v-if="sign_stat==5 || sign_stat==7"
        style="width:calc(100% / 7);position:absolute;left:calc(100% / 7 *4);"
      >
        <el-button @click="view_pay(item)" size="small" type="primary">付款查询</el-button>
      </div>
      <div
        v-if="planType=='bq_order' && sign_stat==7"
        style="width:calc(100% / 7);position:absolute;left:calc(100% / 7 * 6);"
      >
        <el-button @click="goto('/factory/zysp')" size="small" type="primary" plain>前往自有商品查看</el-button>
      </div>
    </div>
    <div v-if="sign_stat>20" class="group-button flex">
      <div style="position:absolute;left:calc(100% / 7 *1);color:#808080;">
        <span v-if="sign_stat==21">卖方已修改商品信息</span>
        <span v-if="sign_stat==22">库存不足</span>
      </div>
    </div>
    <div class="item-plan">
      <el-steps
        :active="sign_stat"
        :finish-status="sign_stat>20?'error' : 'finish'"
        :align-center="sign_stat<21"
      >
        <el-step
          status="finish"
          :style="sign_stat>20 ? 'flex-basis: 150px;' : ''"
          title="等待我方签约"
          icon="iconfont icon-dggfg1"
        ></el-step>
        <template v-if="sign_stat<21" :style="sign_stat>20 ? 'display:none' : ''">
          <el-step title="我方已签约" icon="iconfont icon-dggfg1"></el-step>
          <el-step title="等待对方签约" icon="iconfont icon-dggfg1"></el-step>
          <el-step title="双方已签约" icon="iconfont icon-dggfg1"></el-step>
          <el-step title="等待我方付款" icon="iconfont icon-dggfg1"></el-step>
          <el-step title="对方已收款" icon="iconfont icon-dggfg1"></el-step>
          <el-step title="交易完成" icon="iconfont icon-dggfg1"></el-step>
        </template>
        <el-step
          v-if="sign_stat>20"
          :style="sign_stat<21 ? 'display:none' : 'flex-basis: 150px;'"
          title="交易失败"
          icon="iconfont icon-dggfg1"
        ></el-step>
      </el-steps>
    </div>

    <el-dialog @close="refresh" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
      <div v-if="popTitle == '签约'">
        <Sign :popTitle="popTitle" @data="refresh" @signState="signState" @hide="hide"></Sign>
      </div>
      <div v-if="popTitle == '遇到问题'">
        <SignBack :popTitle="popTitle" @hide="popVisible=false"></SignBack>
      </div>
      <div v-if="popTitle == '请选择签约人'">
          <SignSelect :popTitle="popTitle" :user_id="seal_user_id" :sealMember="sealMember" @hide="popVisible=false" @success="buy"></SignSelect>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import { listSealMember, contractAddr, contractView, contractDownload } from "@/service/contract";
import { getTransId, getPayId } from "@/service/pay";
import Sign from "@/components/sign/sign.vue";
import SignBack from "@/components/sign/signBack.vue";
import SignSelect from '@/components/sign/SignSelect.vue';
export default {
  components: {
    Sign,
    SignBack,
    SignSelect
  },
  props: ["item", "planType"],
  data() {
    return {
      popTitle: null,
      popVisible: false,
      sign_stat: 0,
      timer: null, // 签约相关
      loading: null,
      seal_user_id: null, // 合同签约人ID
      sealMember: [], // 合同签约人列表
    };
  },
  watch: {
    item: {
      handler() {
        this.signStat();
      },
      deep: true
    }
  },
  created() {
    this.seal_user_id = this.$store.state.loginUser.user.id;
    // console.log(this.item);
    // 签约状态
    this.signStat();
  },
  mounted() {},
  methods: {
    signStat() {
      switch (this.item.tradeOrder && this.item.tradeOrder.state) {
        case 0:
          this.sign_stat = 0;
          break;
        case 1:
          this.sign_stat = 1;
          break;
        case 2:
          this.sign_stat = 2;
          break;
        case 3:
          this.sign_stat = 2;
          break;
        case 4:
          this.sign_stat = 4;
          break;
        case 5:
          this.sign_stat = 5;
          break;
        case 6:
          this.sign_stat = 7;
          break;
        case 11:
          this.sign_stat = 7;
          break;
        case 21:
          this.sign_stat = 21;
          break;
        case 22:
          this.sign_stat = 22;
          break;
      }
    },
    refresh() {
      this.$emit("refresh");
    },
    goto(path) {
      this.$router.push(path);
    },
    lookContract(item) { // 查看合同
      if (item.contract_no) {
        window.open(contractView(item.contract_no));
      }
    },
    downloadContract(item) { // 下载合同
      if (item.contract_no) {
        location.href = contractDownload(item.contract_no);
      }
    },
    // 发起签约
    gotoSign() {
      if (this.$store.state.loginUser.comp.cert_stat!=2) {
          this.$message.error("企业未认证");
          return;
      } else {
        this.$confirm("确认是否发起签约?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning"
        }).then(() => {
          let obj = {
              seal_type: 1, // 印章类型，0保留，1设计作品版权出售合同章，2商品打样合同章，3代加工合同章(需求定制)
              page: 1,
              pagesize: 15
          }
          if (this.planType == "bq_order") {
            obj.seal_type = 1;
          } else if (this.planType == "dy_order") {
            obj.seal_type = 2;
          } else {
            obj.seal_type = 3;
          }
          listSealMember(obj).then(res=>{
              if (res && res.list && res.list.length>0) {
                  this.sealMember = res.list;
                  this.selectSign();
              } else {
                  this.going(this.item.contract_no);
              }
          }).catch(err=>{
              this.$message.error(err.message);
          });
        });
      }
      
    },
    selectSign() { // 关闭签约人弹框
        this.popTitle = '请选择签约人';
        this.popVisible = true;
    },
    buy(user_id) { // 选择完签约人回调
      this.going(this.item.contract_no,user_id);
    },
    going(contract_no, user_id) {
      this.loading = Loading.service({
        fullscreen: true,
        text: "拼命加载中....",
        target: this.$parent.$refs.load
      });
      contractAddr(contract_no,user_id).then(rst => {
        console.log(rst);
        if (rst && rst.url) {
          this.sign(rst.url);
          // clearInterval(this.timer);
          // return;
        }
      }).catch(e => {
        this.$message.error(e);
        this.loading.close();
        clearInterval(this.timer);
      });
      // this.timer = setInterval(() => {
        
      // }, 2000);
    },
    sign(url) {
      this.loading.close();
      if (url) window.open(url);
      this.signState("签约");
    },
    hide() {
      this.popVisible = false;
      this.$message.success("状态已更新");
    },
    signState(text) {
      this.popTitle = text;
      this.popVisible = true;
    },
    // 去支付
    goPay(item) {
      // let trans_id = await getTransId(item.tradeOrderId);
      let comp_id = null;
      if (this.planType == "dy_order") {
        comp_id = item.sellerComp.id;
      } else if (this.planType == "bq_order") {
        comp_id = item.design_comp.id;
      } else {
        comp_id = item.sellerComp.id;
      }
      // else if (this.planType == 'gk_order') {
      //     comp_id = item.sellerComp.id;
      // }
      if (item.tradeOrder && item.tradeOrder.id) {
        this.$router.push({
          path: "/pay/waitPay",
          query: {
            type: 0,
            // oType:this.planType,
            order_id: item.tradeOrder.id,
            comp_id: comp_id
          }
        });
      }
    },
    // 查看付款记录
    view_pay(item) {
      this.$router.push({
        path: "/pay/payList",
        query: {
          order_id: item.tradeOrder.id
        }
      });
    }
  }
};
</script>
<style scoped>
.signPlan {
  margin-left: 25px;
}
.group-button {
  height: 60px;
  position: relative;
  text-align: center;
  padding: 10px 0;
}
.item-plan >>> .el-step__icon.is-icon,
.el-step__icon {
  width: unset;
}
</style>